<template>
  <div>
    <el-dialog class="dialogs" :title="titles" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" :rules="drules" label-width="100px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item :label-width="labelWidth" prop="refuse">
              <el-input v-model="dform.refuse" placeholder="请输入备注">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="cancelfun()">取 消</el-button> -->
        <!-- <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button> -->
        <el-button type="primary" @click="beforeSub">确 定</el-button>
      </span>
    </el-dialog>
    <!-- </div> -->
  </div>
</template>

<script>
// import { AddImg } from '@/api/personnel'
import { Upimg } from '@/api/upimg'
import { FormPass, BillPass } from '@/api/shouhuo'
import { getList as getCarDetail } from '@/api/carindetail'

export default {
  name: 'refusedia',
  props: {
    dialogVisible: Boolean,
    isFormOrBill: String,
    refuseId: Number
  },
  data() {
    return {
      labelWidth: '0px',
      dform: {
        refuse: '',
        check_pass: 0
      },
      // 验证规则
      drules: {
        refuse: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      titles: ''
    }
  },
  async mounted() {},
  methods: {
    // 提交前处理
    async beforeSub() {
      console.log(this.dform)
      this.$refs['dform'].validate(async v => {
        if (v) {
          let subObj = JSON.parse(JSON.stringify(this.dform))
          subObj.reimburse_id = this.refuseId
          if (this.isFormOrBill === 'form') {
            // alert(1)
            const { data } = await FormPass(subObj)
            if (data.code !== 200) {
              return this.$message.error('拒绝失败')
            } else {
              this.$message.success('拒绝成功')
            }
          } else if (this.isFormOrBill === 'bill') {
            // alert(2)
            const { data } = await BillPass(subObj)
            if (data.code !== 200) {
              return this.$message.error('拒绝失败')
            } else {
              this.$message.success('拒绝成功')
            }
          }
          this.$emit('update')
          this.handleClose()
        }
      })
    },
    clearAll() {
      this.dform = {
        refuse: '',
        check_pass: 0
      }
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('handleClose')
      this.clearAll()
    }
  },
  watch: {
    dialogVisible: {
      handler(newV, oldV) {
        console.log(newV, 'dialogVisible')
      },
      deep: true,
      immediate: true
    },
    isFormOrBill: {
      async handler(newV, oldV) {
        console.log(newV, 'infoObj')
        if (newV == 'bill') {
          this.titles = '票据审核备注'
        } else {
          this.titles = '记录审核备注'
        }
      },
      deep: true
      // immediate: true
    }
  }
}
</script>
<style scoped lang="less">
.dialogs {
  /deep/ .el-date-editor {
    width: 100% !important;
  }
  /deep/ .el-select {
    width: 100% !important;
  }
  /deep/ .el-input__inner {
    width: 100% !important;
  }

  .detailBox {
    margin-top: 50px;
  }
  .personBox {
  }
}
</style>
