<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>售后管理</el-breadcrumb-item>
      <el-breadcrumb-item>报销票据审核</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card aaass">
      <div class="herd">
        <div class="tops riqi">
          <span>日期筛选：</span>
          <el-date-picker v-model="yue" type="month" @change="gettime" value-format="yyyy-MM" placeholder="选择月" clearable></el-date-picker>
        </div>
        <div class="tops">
          <span>报销公司：</span>
          <el-select v-model="company" placeholder="未选择" clearable @clear="handlefindComp">
            <el-option v-for="item in companis" :key="item.key" :label="item.val" :value="item.key" @click.native="findComp"></el-option>
          </el-select>
        </div>

        <div class="tops">
          <span>费用所属单位：</span>
          <el-select v-model="daqu" placeholder="未选择" clearable @clear="handledaqu">
            <el-option v-for="item in orgs" :key="item.key" :label="item.val" :value="item.key" @click.native="chdaqu"></el-option>
          </el-select>
        </div>

        <div class="tops">
          <span>费用种类：</span>
          <el-select v-model="feiyong" placeholder="未选择" clearable @clear="handlefeiyong">
            <el-option v-for="item in fees" :key="item.key" :label="item.val" :value="item.key" @click.native="chdafei"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>报销人：</span>
          <el-select v-model="userid" placeholder="未选择" clearable @clear="handleuserid">
            <el-option v-for="item in renlist" :key="item.key" :label="item.val" :value="item.key" @click.native="choicedrug"></el-option>
          </el-select>
        </div>
        <div class="tops">
          <span>是否有发票：</span>
          <el-select v-model="fa" placeholder="未选择" clearable @clear="handlefa">
            <el-option v-for="item in bills" :key="item.key" :label="item.val" :value="item.key" @click.native="chdafapiao"></el-option>
          </el-select>
        </div>
        <!-- <div class="tops">
          <el-button size="mini" type="primary" @click="exportJilu">导出报销记录</el-button>
        </div> -->
      </div>

      <div style="margin: 10px 0 0 0; font-size: 20px">待审核列表</div>
      <el-table :data="tableDataBefore" stripe style="width: 100%" :row-style="{ height: '50px' }">
        <!-- <el-table-column prop="reimburse_time" label="提交时间" sortable></el-table-column>
        <el-table-column prop="reimburse_no" label="报销编号"></el-table-column>
        <el-table-column prop="department" label="费用所属单位" align="center"></el-table-column>
        <el-table-column prop="project_no" label="项目编号"></el-table-column>
        <el-table-column prop="reimburse_user" label="报销人" width="70"></el-table-column>
        <el-table-column prop="cost_type" label="费用种类" align="center"></el-table-column>
        <el-table-column prop="firm" label="报销公司" width="100" align="center"></el-table-column>
        <el-table-column prop="amount" label="报销金额(元)" width="90" align="center"></el-table-column>
        <el-table-column prop="invoice_name" label="是否有发票" width="90" align="center"></el-table-column>
        <el-table-column prop="detail" label="报销事项"></el-table-column> -->
        <el-table-column prop="reimburse_time" label="提交时间" sortable align="center"></el-table-column>
        <el-table-column prop="reimburse_no" label="报销编号" width="147" align="center"></el-table-column>
        <el-table-column prop="department" label="费用所属单位" align="center"></el-table-column>
        <el-table-column prop="firm" label="报销公司" width="100" align="center"></el-table-column>
        <el-table-column prop="project_no" label="项目编号" align="center"></el-table-column>
        <el-table-column prop="reimburse_user" label="报销人" width="70" align="center"></el-table-column>
        <el-table-column prop="cost_type" label="费用种类" align="center"></el-table-column>
        <el-table-column prop="amount" label="报销金额(元)" width="90" align="center"></el-table-column>
        <el-table-column prop="invoice_name" label="是否有发票" width="90" align="center"></el-table-column>
        <el-table-column prop="detail" label="报销事项"></el-table-column>
        <el-table-column label="支付图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div">
              <span class="span" v-if="scope.row.pay_images.length !== 1">+{{ scope.row.pay_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.pay_images[0]" :preview-src-list="scope.row.pay_images"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审核图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div">
              <span class="span" v-if="scope.row.auth_images.length !== 1">+{{ scope.row.auth_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.auth_images[0]" :preview-src-list="scope.row.auth_images"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收据图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div" v-show="scope.row.receipt_images.length != 0">
              <span class="span" v-if="scope.row.receipt_images.length !== 1">+{{ scope.row.receipt_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.receipt_images[0]" :preview-src-list="scope.row.receipt_images"></el-image>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="150px" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" @click="passfun(scope.row.reimburse_id)">通过</el-button>
            <el-button size="mini" type="danger" @click="refusefun(scope.row.reimburse_id)">拒绝</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageData" @newsize="bianzhi" @newpape="bianye" />

      <div style="margin: 10px 0 0 0; font-size: 20px">票据不合格列表</div>
      <el-table :data="tableDataAfter" stripe style="width: 100%" :row-style="{ height: '50px' }">
        <!-- <el-table-column prop="reimburse_time" label="提交时间" sortable></el-table-column>
        <el-table-column prop="reimburse_no" label="报销编号"></el-table-column>
        <el-table-column prop="department" label="费用所属单位" align="center"></el-table-column>
        <el-table-column prop="project_no" label="项目编号"></el-table-column>
        <el-table-column prop="reimburse_user" label="报销人" width="70"></el-table-column>
        <el-table-column prop="cost_type" label="费用种类" align="center"></el-table-column>
        <el-table-column prop="firm" label="报销公司" width="100" align="center"></el-table-column>
        <el-table-column prop="amount" label="报销金额(元)" width="90" align="center"></el-table-column>
        <el-table-column prop="invoice_name" label="是否有发票" width="90" align="center"></el-table-column>
        <el-table-column prop="detail" label="报销事项"></el-table-column> -->
        <el-table-column prop="reimburse_time" label="提交时间" sortable align="center"></el-table-column>
        <el-table-column prop="reimburse_no" label="报销编号" width="147" align="center"></el-table-column>
        <el-table-column prop="department" label="费用所属单位" align="center"></el-table-column>
        <el-table-column prop="firm" label="报销公司" width="100" align="center"></el-table-column>
        <el-table-column prop="project_no" label="项目编号" align="center"></el-table-column>
        <el-table-column prop="reimburse_user" label="报销人" width="70" align="center"></el-table-column>
        <el-table-column prop="cost_type" label="费用种类" align="center"></el-table-column>
        <el-table-column prop="amount" label="报销金额(元)" width="90" align="center"></el-table-column>
        <el-table-column prop="invoice_name" label="是否有发票" width="90" align="center"></el-table-column>
        <el-table-column prop="detail" label="报销事项"></el-table-column>
        <el-table-column label="支付图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div">
              <span class="span" v-if="scope.row.pay_images.length !== 1">+{{ scope.row.pay_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.pay_images[0]" :preview-src-list="scope.row.pay_images"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="审核图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div">
              <span class="span" v-if="scope.row.auth_images.length !== 1">+{{ scope.row.auth_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.auth_images[0]" :preview-src-list="scope.row.auth_images"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="收据图片" width="90" class="picsya">
          <template slot-scope="scope">
            <div class="div" v-show="scope.row.receipt_images.length != 0">
              <span class="span" v-if="scope.row.receipt_images.length !== 1">+{{ scope.row.receipt_images.length }}</span>
              <el-image style="width: 50px; height: 50px" :src="scope.row.receipt_images[0]" :preview-src-list="scope.row.receipt_images"></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150px" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button size="mini" type="primary" @click="passfun(scope.row.reimburse_id)">通过</el-button>
            <!-- <el-button size="mini" type="danger" @click="refusefun(scope.row.reimburse_id)">拒绝</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <Fenye :page-data="pageDataTwo" @newsize="bianzhitwo" @newpape="bianyetwo" />
    </el-card>
    <!--  弹窗内容-->
    <refusedia :dialogVisible="dialogVisible" :isFormOrBill="isFormOrBill" :refuseId="refuseId" @handleClose="handleClose" @update="updateList" />
  </div>
</template>
<script>
import Fenye from '../../../components/Fenye'
import refusedia from './components/refuseDia.vue'
import { BillPass } from '@/api/shouhuo'
import { GetJiluDetail, GetJilulist, GetJiluExport, GetJiluSearch } from '../../../api/shouhuo'
export default {
  components: {
    Fenye,
    refusedia
  },
  data() {
    return {
      dialogVisible: false, // 弹窗控制
      infoObj: {},
      isFormOrBill: '',
      fa: '',
      feiyong: '',
      company: '',
      daqu: '',
      userid: '',
      renlist: [],
      yue: '',
      tableDataBefore: [],
      tableDataAfter: [],
      orgs: [],
      fees: [],
      companis: [],
      bills: [
        {
          key: '',
          val: '全部'
        },
        {
          key: '1',
          val: '有发票'
        },
        {
          key: '0',
          val: '无发票'
        }
      ],
      pageData: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      pageDataTwo: {
        // 当前页数
        page: 1,
        // 当前页数上面的数量
        size: 10,
        // 共有多少条
        count: 0
      },
      refuseId: 0
    }
  },
  mounted() {
    this.Getlist()
    this.GetTwoList()
    this.Getshaixuan()
  },
  methods: {
    handlefindComp() {
      this.company = ''
      this.Getlist()
      this.GetTwoList()
    },

    handledaqu() {
      this.daqu = ''
      this.Getlist()
      this.GetTwoList()
    },

    handlefeiyong() {
      this.feiyong = ''
      this.Getlist()
      this.GetTwoList()
    },

    handleuserid() {
      this.userid = ''
      this.Getlist()
      this.GetTwoList()
    },

    handlefa() {
      this.fa = ''
      this.Getlist()
    },
    updateList() {
      this.Getlist()
      this.GetTwoList()
    },
    passfun(id) {
      let subObj = {
        reimburse_id: id,
        check_pass: 1
      }
      this.$confirm('请确认通过！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data } = await BillPass(subObj)
          if (data.code !== 200) {
            return this.$message.error('通过失败')
          } else {
            this.$message.success('通过成功')
          }
          this.Getlist()
          this.GetTwoList()
        })
        .catch(err => {
          console.log(err, 'err')
          this.$message({
            type: 'info',
            message: '已取消通过'
          })
        })
    },
    refusefun(id) {
      // 打开弹窗
      this.opentan('bill', id)
    },
    // 打开弹窗
    opentan(type, id) {
      this.dialogVisible = true
      this.isFormOrBill = type
      this.refuseId = id
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false
    },
    chdaqu() {
      console.log(this.daqu)
      this.Getlist()
      this.GetTwoList()
    },
    findComp() {
      console.log(this.company)
      this.Getlist()
      this.GetTwoList()
    },
    chdafei() {
      console.log(this.feiyong)
      this.Getlist()
      this.GetTwoList()
    },
    chdafapiao() {
      console.log(this.fa)
      this.Getlist()
      this.GetTwoList()
    },
    bianzhitwo(newSize) {
      // 分页值变化
      this.pageDataTwo.size = newSize
      this.GetTwoList()
    },
    bianyetwo(newPape) {
      // 分页页数变化
      this.pageDataTwo.page = newPape
      this.GetTwoList()
    },
    bianzhi(newSize) {
      // 分页值变化
      this.pageData.size = newSize
      this.Getlist()
    },
    bianye(newPape) {
      // 分页页数变化
      this.pageData.page = newPape
      this.Getlist()
    },
    // 时间
    gettime(yue) {
      this.Getlist()
      this.GetTwoList()
    },
    // 选中人
    choicedrug() {
      this.Getlist()
      this.GetTwoList()
    },
    async Getlist() {
      let param = {
        ...this.pageData,
        month: this.yue,
        user: this.userid,
        proj: this.daqu,
        cost_type: this.feiyong,
        // invoice: this.fa,
        firm: this.company
      }
      if (this.fa != '') {
        param.invoice = this.fa
      }
      param.status = '1'
      const { data } = await GetJilulist(param)
      this.pageData.count = data.data.count
      // this.pageData.page = data.data.page * 1
      if (data.data.data.length !== 0) {
        data.data.data.map(item => {
          if (item.invoice == 1) {
            item.invoice_name = '有发票'
          } else {
            item.invoice_name = '无发票'
          }
          item.amount = item.amount / 100
          item.amount = item.amount.toFixed(2)
        })
      }
      // this.tableData = data.data.data
      this.tableDataBefore = data.data.data
      // let before = []
      // let after = []
      // data.data.data.map(item => {
      //   if (item.refused.length === 0) {
      //     before.push(item)
      //   } else {
      //     after.push(item)
      //   }
      // })
      // this.tableDataBefore = JSON.parse(JSON.stringify(before))
      // this.tableDataAfter = JSON.parse(JSON.stringify(after))
      // console.log(this.tableData, 'this.tableData')
      this.getanpostfun(data, '获取')
    },
    async GetTwoList() {
      let param = {
        ...this.pageDataTwo,
        month: this.yue,
        user: this.userid,
        proj: this.daqu,
        cost_type: this.feiyong,
        // invoice: this.fa,
        firm: this.company
      }
      if (this.fa != '') {
        param.invoice = this.fa
      }
      param.status = '4'
      const { data } = await GetJilulist(param)
      this.pageDataTwo.count = data.data.count
      // this.pageData.page = data.data.page * 1
      if (data.data.data.length !== 0) {
        data.data.data.map(item => {
          if (item.invoice == 1) {
            item.invoice_name = '有发票'
          } else {
            item.invoice_name = '无发票'
          }
          item.amount = item.amount / 100
          item.amount = item.amount.toFixed(2)
        })
      }
      this.tableDataAfter = data.data.data
    },
    async exportJilu() {
      const { data } = await GetJiluExport()
      console.log(data, 'data')
      let url = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }))
      // 创建a标签，并隐藏a标签
      let link = document.createElement('a')
      link.style.display = 'none'
      // a标签的href属性指定下载链接
      link.href = url
      //setAttribute() 方法添加指定的属性，并为其赋指定的值
      // 后缀格式.csv/.xsls要和需要和后端返回格式相同，这里以.csv为例
      link.setAttribute('download', '报销记录')
      document.body.appendChild(link)
      link.click()
      // this.renlist = data.data
    },

    async Getshaixuan() {
      const { data } = await GetJiluSearch({ status: '4' })
      const res = await GetJiluSearch({ status: '1' })
      console.log(res.data)
      let datas = res.data.data
      // a.concat(b.filter(function(v){ return !(a.indexOf(v) > -1)}));
      const orgs1 = data.data.projs.map(item => JSON.stringify(item))
      const orgs2 = datas.projs.map(item => JSON.stringify(item))
      this.orgs = Array.from(new Set([...orgs1, ...orgs2]))
      this.orgs = this.orgs.map(item => JSON.parse(item))

      const costType1 = data.data.cost_type.map(item => JSON.stringify(item))
      const costType2 = datas.cost_type.map(item => JSON.stringify(item))
      this.fees = Array.from(new Set([...costType1, ...costType2]))
      this.fees = this.fees.map(item => JSON.parse(item))
      // console.log(this.fees)
      // this.bills = data.data.bills
      const firms1 = data.data.firms.map(item => JSON.stringify(item))
      const firms2 = datas.firms.map(item => JSON.stringify(item))
      this.companis = Array.from(new Set([...firms1, ...firms2]))
      this.companis = this.companis.map(item => JSON.parse(item))
      const users1 = data.data.users.map(item => JSON.stringify(item))
      const users2 = datas.users.map(item => JSON.stringify(item))
      this.renlist = Array.from(new Set([...users1, ...users2]))
      this.renlist = this.renlist.map(item => JSON.parse(item))
      // console.log(this.bills)
    },

    // 公共方法
    getanpostfun(data, text, fun) {
      if (data.code === 200) {
        this.$message.success(`${text}成功`)
        fun
      } else {
        return this.$message.error(`${text}失败,原因：${data.message}`)
      }
    }
  }
}
</script>

<style>
.herd {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.aaass {
  box-sizing: border-box;
}
.picsya {
  position: relative;
}
.div {
  position: relative;
  width: 90%;
  height: 50%;
}
.span {
  text-align: center;
  width: 80%;
  height: 80%;
  font-weight: 300;
  position: absolute;
  top: 2%;
  left: 0%;
  box-sizing: border-box;
  padding-top: 12%;
  margin: auto;
  color: #545c64;
  font-size: 30px;
  z-index: 2;
  pointer-events: none;
}
</style>
